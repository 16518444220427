import { useDispatch, useSelector } from "react-redux";
import { useMemo, useState } from "react";
import { useRouter } from "next/router";
import { round } from "lodash";
import styles from "./index.module.scss";
import Icon from "../../icon";
import Button from "../../button";
import { getBacket } from "../../../../redux/action-creaters/cart";
import { showPopup } from "/redux/action-creaters/popup";
import { popupTypes } from "/redux/reducers/popupReducer";
import { Api } from "/api";
import Split from "../split";

export default function Offer({
  product,
  isDetail = false,
  isShowSplit = false,
  isBaltex = false,
}) {
  const name = product.NAME;
  const productId = product.ID;
  const catalog = product.CATALOG;

  const { sections } = useSelector((state) => state.sections);

  const dispatch = useDispatch();
  const [isAddingToCart, setIsAddingToCart] = useState(false);

  const { basket } = useSelector((state) => state.cart);

  const isInCart = useMemo(() => {
    return basket?.ITEMS?.find((item) => +productId === +item.PRODUCT_ID);
  }, [basket]);

  const quantity = useMemo(() => {
    return catalog.QUANTITY;
  }, [catalog]);

  const isAlailable = useMemo(() => {
    return catalog.AVAILABLE;
  }, [catalog]);

  const price = useMemo(() => {
    return catalog.MIN_PRICE;
  }, [catalog]);

  const noDiscoutPrice = useMemo(() => {
    return catalog.PRICE;
  }, [catalog]);

  const edostMinPriceCorrected = useMemo(() => {
    return 0;
  }, [catalog]);

  const priceWithCity = useMemo(() => {
    return price + edostMinPriceCorrected;
  }, [edostMinPriceCorrected]);

  const { user } = useSelector((state) => state.user);

  const { cityName } = useSelector((state) => state.location);

  const installPrice = useMemo(() => {
    if (!sections[product.IBLOCK_SECTION_ID]?.UF_INSTALL_PRICE) {
      return 0;
    }
    const cityList = {
      Москва: "msk",
      "Санкт-Петербург": "spb",
    };
    const cityCode = cityList[cityName];
    if (!cityCode) {
      return 0;
    }

    const sectionHourPrices = {};
    sections[product.IBLOCK_SECTION_ID].UF_INSTALL_PRICE.forEach((item) => {
      const itemArray = item.split(":");
      sectionHourPrices[itemArray[0]] = +itemArray[1];
    });
    const installPriceForCity = sectionHourPrices[cityCode];
    if (installPriceForCity && product.VREMYA_USTANOVKI) {
      let price = round((installPriceForCity / 60) * product.VREMYA_USTANOVKI);
      if (product.INSTALL_COEFFICIENT) {
        price = round((price * product.INSTALL_COEFFICIENT) / 100);
      }
      return price;
    }
    return 0;
  }, [product, catalog, cityName]);

  const { contactsData } = useSelector((state) => state.contacts);
  const router = useRouter();

  return (
    <>
      {!isBaltex && (
        <div className={styles.Qty}>
          {quantity > 0 ? (
            <>
              <Icon id="yes" width={18} height={18} />
              <span className={styles.InStock}>В наличии: {quantity} шт</span>
            </>
          ) : (
            <span className={styles.OutOfStock}>Нет в наличии</span>
          )}
        </div>
      )}
      <div
        className={styles.Price}
        itemProp="offers"
        itemScope
        itemType="http://schema.org/Offer"
      >
        {isAlailable && noDiscoutPrice > price && (
          <div className={styles.BeforeValue}>{noDiscoutPrice} руб</div>
        )}

        {!isBaltex && (
          <div className={styles.Value}>
            {" "}
            {isAlailable && (
              <>
                <span>
                  <span itemProp="price">{priceWithCity}</span> руб
                </span>
              </>
            )}
          </div>
        )}
        {isBaltex && (
          <div className={styles.ValueBaltex}>
            <div className={styles.BaltexArt}>
              <span>Арт. {product.ARTICLE?.VALUE}</span>
            </div>
            {isAlailable && (
              <>
                <span>
                  <span itemProp="price">{priceWithCity}</span> руб
                </span>
              </>
            )}
          </div>
        )}
        {isBaltex && (
          <div className={styles.BaltexBottom}>
            <div className={styles.Qty}>
              {quantity > 0 ? (
                <>
                  <span className={styles.InStock}>
                    В наличии: {quantity} шт
                  </span>
                </>
              ) : (
                <span className={styles.OutOfStock}>Нет в наличии</span>
              )}
            </div>
          </div>
        )}

        {isAlailable ? (
          <>
            {installPrice > 0 &&
              (user?.IS_ADMIN || Boolean(router.query.demo)) && (
                <div className={styles.Install}>
                  <span className={styles.Hint}>
                    <Icon id={"i"} fill width={20} height={20} />
                    <span className={styles.HintText}>
                      Стоимость установки носит исключительно информационный
                      характер, за более подробной консультации вы можете
                      обратиться по телефону:
                      <br /> {contactsData.PHONE}
                    </span>
                  </span>
                  <span>С установкой +{installPrice} руб</span>
                </div>
              )}
            {installPrice > 0 &&
              (user?.IS_ADMIN || Boolean(router.query.demo)) && (
                <div className={styles.InstallManager}>
                  <div>Время установки - {product.VREMYA_USTANOVKI} мин</div>
                  {product.INSTALL_COEFFICIENT > 0 && (
                    <div>коэффициент - {product.INSTALL_COEFFICIENT}</div>
                  )}
                </div>
              )}
            <span itemProp="priceCurrency" style={{ display: "none" }}>
              RUB
            </span>
            <link itemProp="availability" href="http://schema.org/InStock" />
            <div className={styles.Buttons}>
              {isBaltex && (
                <div>
                  <Button
                    onClick={async () => {
                      window.open(
                        `https://farkop.ru${product.DETAIL_PAGE_URL}`,
                        "_blank",
                      );
                    }}
                    label={"Купить"}
                  />
                  <div className={styles.ParnersText}>
                    Покупка производится на сайте партнера farkop.ru
                  </div>
                </div>
              )}
              {!isBaltex && (
                <Button
                  type={isInCart ? "outline" : ""}
                  onClick={async () => {
                    if (!isInCart) {
                      setIsAddingToCart(true);
                      await Api.basket.addToBasket(productId, priceWithCity);
                      await dispatch(getBacket(true));
                      await dispatch(showPopup(popupTypes.cart));
                      setIsAddingToCart(false);

                      if (typeof ym !== "undefined") {
                        // eslint-disable-next-line no-undef
                        ym(20518312, "reachGoal", "addToCart");
                      }
                    } else {
                      router.push("/personal/cart/");
                    }

                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                      ecommerce: {
                        currencyCode: "RUB",
                        add: {
                          products: [
                            {
                              id: productId,
                              name,
                              price,
                              quantity: 1,
                              category:
                                sections[product.IBLOCK_SECTION_ID].NAME,
                              brand: product.BRAND?.name,
                              list: isDetail ? "Детальная страница" : "Каталог",
                            },
                          ],
                        },
                      },
                    });
                  }}
                  label={isInCart ? "В корзине" : "Купить"}
                  isLoading={isAddingToCart}
                />
              )}
              {!isBaltex && (
                <Button
                  type="outline"
                  label="Купить в 1 клик"
                  onClick={() => {
                    dispatch(
                      showPopup(popupTypes.buyOneClick, {
                        id: productId,
                        price: priceWithCity,
                      }),
                    );
                  }}
                />
              )}
            </div>
            <span className={styles.Separator} />
            <div
              className={styles.Link}
              onClick={() => {
                dispatch(
                  showPopup(popupTypes.findLowPrice, {
                    name,
                    id: productId,
                    price: priceWithCity,
                  }),
                );
              }}
            >
              Нашли дешевле?
            </div>

            {isShowSplit &&
              (user.IS_MANAGER ||
                user.IS_ADMIN ||
                Boolean(router.query.demo)) && <Split price={price} />}
          </>
        ) : (
          <>
            <link itemProp="availability" href="http://schema.org/OutOfStock" />
            <Button
              type="outline"
              label={"Оформление предзаказа"}
              onClick={() => {
                dispatch(
                  showPopup(popupTypes.subscribeProduct, {
                    name: `${product.NAME} (арт ${product.ARTICLE?.VALUE}) (id ${product.ID})`,
                    id: productId,
                  }),
                );
              }}
            />
          </>
        )}
      </div>
    </>
  );
}
